function Schlong() {
  const schlong = "images/schlong-with-ticker.jpg";

  return (
    <>
      <img
        src={schlong}
        className="w-min aspect-auto max-h-40 mx-auto my-3"
        alt=""
      />
    </>
  );
}

export default Schlong;
